
import { defineComponent, ref, onMounted } from "vue";
import { ApiBase, ApiLogisticsServiceProvider } from "@/core/api";
import { useI18n } from "vue-i18n";

interface Filter {
  shipment_type: string | number;
  fulfillment_stage: Array<number>;
  service_provider: Array<number>;
  tracking_no: string;
  sale_order_number: string;
  fulfillment_order_number: string;
  shipment_id: string | number;
  created_at_start: null | string;
  created_at_end: null | string;
  status: string | number;
}

interface TaggingItem {
  label: string;
  value: string;
}

export default defineComponent({
  name: "filter-shipments-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const formData = ref<Filter>({
      shipment_type: "",
      fulfillment_stage: [],
      service_provider: [],
      tracking_no: "",
      sale_order_number: "",
      fulfillment_order_number: "",
      shipment_id: "",
      created_at_start: null,
      created_at_end: null,
      status: "",
    });

    const options = ref({
      status: [] as TaggingItem[],
      shipment_type: [
        // {
        //   value: "1",
        //   label: t("shipments.eu"),
        // },
        // {
        //   value: "2",
        //   label: t("shipments.os"),
        // },
        // {
        //   value: "3",
        //   label: t("shipments.cn"),
        // },
      ] as TaggingItem[],
      fulfillment_stage: [] as TaggingItem[],
      service_provider: [] as TaggingItem[],
    });

    const trackingNoChange = (e) => {
      formData.value.tracking_no = e.split(" ").join("");
    };

    const getLogisticsServiceProviderData = async () => {
      const { data } =
        await ApiLogisticsServiceProvider.getLogisticsServiceProviderData({
          max_item: "all",
        });
      if (data.code == 0) {
        let shipmentProviderOptionsData = data.data;
        let pickUp = {
          label: t("shipments.pickUpBy"),
          value: -1,
        };
        shipmentProviderOptionsData.push(pickUp);
        options.value.service_provider = shipmentProviderOptionsData;
      }
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["shipment_status", "shipment_type", "fulfillment_stage"],
      });
      if (data.code == 0) {
        options.value.status = data.data.shipment_status.items;
        options.value.shipment_type = data.data.shipment_type.items;
        options.value.fulfillment_stage = data.data.fulfillment_stage.items;
      }
    };

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onMounted(() => {
      getLogisticsServiceProviderData();
      getTaggingData();
    });

    return {
      t,
      formData,
      formRef,
      options,
      trackingNoChange,
      submit,
      handleReset,
    };
  },
});
