
import { defineComponent, ref, onMounted, computed, onActivated } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import FilterDropdown from "@/views/transport-management/shipments/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiShipments } from "@/core/api";
import addShipments from "@/views/transport-management/shipments/AddTmsShipments.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import fileDownload from "js-file-download";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { FilterOption, SortOption } from "@/core/directive/interface/common";
import { ShipmentTable } from "@/core/directive/interface/shipment";
import { CommonButtonType } from "@/core/directive/type/common";
import store from "@/store";
import {
  getServiceProviderMap,
  getShipmentStatusMap,
} from "@/core/directive/function/shipment";
import {
  commonChangeFilterAddDate,
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "transport-management-shipments",
  components: {
    MBDatatable,
    FilterDropdown,
    addShipments,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const loading = ref(true);

    const disabledExportOverseaShipmentReport = ref(false);
    const disabledExportLogisticsDetailsReport = ref(false);
    const disabledExportOverseasShipmentTracking = ref(false);
    const disabledExportOutboardReport = ref(false);

    const tableData = ref<Array<ShipmentTable>>([]);
    const checkedShipment = ref<Array<number>>([]);
    const receivedButton = ref<CommonButtonType>(null);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(
      store.getters.currentConfigPageSize(route.name)
    );
    const search = ref<string>("");
    const filterOptions = ref<Array<FilterOption>>([]);

    const sortOptions = ref<Array<SortOption>>([
      { field: "id", direction: "desc" },
    ]);

    const tableHeader = ref([
      {
        name: t("shipments.trackingNo"),
        key: "tracking_no",
        sortable: false,
      },
      {
        name: t("shipments.from"),
        key: "delivery_from",
        sortable: false,
      },
      {
        name: t("shipments.to"),
        key: "destination",
        sortable: false,
      },
      {
        name: t("shipments.departure"),
        key: "actual_time_of_departure",
        sortable: false,
      },
      {
        name: t("shipments.arrival"),
        key: "actual_time_of_arrival",
        sortable: false,
      },
      {
        name: t("shipments.status"),
        key: "status",
        sortable: false,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const init = () => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getShipmentList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    onMounted(() => {
      if (store.getters.getAliveList.length === 0) {
        init();
      }
    });

    onActivated(() => {
      init();
    });

    const getReceiveStatus = (status) => {
      if (status != 40) {
        return true;
      } else {
        return false;
      }
    };

    const getShipmentList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiShipments.getShipmentList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleReceivedShipment = (id) => {
      Swal.fire({
        text: t("common.confirmTheReceiptOfGoods"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (receivedButton.value) {
            receivedButton.value.setAttribute("data-kt-indicator", "on");
          }
          const { data } = await ApiShipments.deliveredShipment({
            id: id,
          });
          receivedButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            Swal.fire({
              text: "Received successfully!",
              icon: "success",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              updateList();
            });
          }
        }
      });
    };

    const updateList = () => {
      getShipmentList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsSelectChange = (selection) => {
      let ids: number[] = [];
      selection.forEach((item) => {
        ids.push(item.id);
      });
      checkedShipment.value = ids;
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      getShipmentList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      updateList();
      store.commit(Mutations.SET_CURRENT_PAGE_CONFIG, {
        key: route.name,
        data: {
          pageSize: pageSize.value,
        },
      });
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getShipmentList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] !== "" && val[item] !== null) {
          if (item == "tracking_no" || item == "shipment_id") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          } else if (item == "created_at_start") {
            filterArr.push({
              field: "created_at",
              value: commonChangeFilterAddDate(val[item], 1),
              condition: "gteq",
            });
          } else if (item == "created_at_end") {
            filterArr.push({
              field: "created_at",
              value: commonChangeFilterAddDate(val[item]),
              condition: "lteq",
            });
          } else if (
            item == "fulfillment_stage" ||
            item == "service_provider"
          ) {
            if (val[item].length > 0)
              filterArr.push({
                field: item,
                value: val[item],
                condition: "in",
              });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getShipmentList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getShipmentList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getShipmentList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const getServiceProvider = computed(() => {
      return (value) => {
        return getServiceProviderMap(value);
      };
    });

    const fileExportOverseaShipmentReport = () => {
      disabledExportOverseaShipmentReport.value = true;
      ApiShipments.exportOverseaShipmentReport({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
        id: checkedShipment.value,
      })
        .then((data) => {
          // console.log(data.headers["content-disposition"]);
          disabledExportOverseaShipmentReport.value = false;
          // const disposition = data.headers["content-disposition"];
          fileDownload(data.data, "Oversea Shipment Report.xlsx");
        })
        .catch((error) => {
          disabledExportOverseaShipmentReport.value = false;
          console.log(error);
        });
    };

    const fileExportLogisticsDetailsReport = () => {
      disabledExportLogisticsDetailsReport.value = true;
      ApiShipments.exportLogisticsDetailsReport({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
        id: checkedShipment.value,
      })
        .then((data) => {
          // console.log(data.headers["content-disposition"]);
          disabledExportLogisticsDetailsReport.value = false;
          // const disposition = data.headers["content-disposition"];
          fileDownload(data.data, "Logistics Details Report.xlsx");
        })
        .catch((error) => {
          disabledExportLogisticsDetailsReport.value = false;
          console.log(error);
        });
    };

    const fileExportOverseasShipmentTracking = () => {
      disabledExportOverseasShipmentTracking.value = true;
      ApiShipments.exportOverseasShipmentTracking({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
        id: checkedShipment.value,
      })
        .then((data) => {
          // console.log(data.headers["content-disposition"]);
          disabledExportOverseasShipmentTracking.value = false;
          // const disposition = data.headers["content-disposition"];
          fileDownload(data.data, "Overseas Shipment Tracking Report.xlsx");
        })
        .catch((error) => {
          disabledExportOverseasShipmentTracking.value = false;
          console.log(error);
        });
    };

    const fileExportOutboardReport = () => {
      disabledExportOutboardReport.value = true;
      ApiShipments.exportOutboardReport({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
        id: checkedShipment.value,
      })
        .then((data) => {
          // console.log(data.headers["content-disposition"]);
          disabledExportOutboardReport.value = false;
          // const disposition = data.headers["content-disposition"];
          fileDownload(data.data, "Outboard Report.xlsx");
        })
        .catch((error) => {
          disabledExportOutboardReport.value = false;
          console.log(error);
        });
    };

    const getLocation = computed(() => {
      return (location, needName: boolean) => {
        let result = "";
        if (needName) {
          result += location.name;
        } else if (location.city) {
          result += location.city;
        }
        if (location.country_iso_2) {
          if ((location.name && needName) || (location.city && !needName)) {
            result += ", ";
          }
          result += location.country_iso_2;
        }
        return result;
      };
    });

    return {
      t,
      formatDate,
      formatDateTime,
      getShipmentStatusMap,
      loading,
      receivedButton,
      tableHeader,
      tableData,
      checkedShipment,
      disabledExportOverseaShipmentReport,
      disabledExportLogisticsDetailsReport,
      disabledExportOverseasShipmentTracking,
      disabledExportOutboardReport,
      currentPage,
      total,
      pageSize,
      search,
      getShipmentList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      searchItems,
      removeSearch,
      handleFilter,
      handleFilterReset,
      updateList,
      handleReceivedShipment,
      getServiceProvider,
      getReceiveStatus,
      onRowsSelectChange,
      fileExportOverseaShipmentReport,
      fileExportLogisticsDetailsReport,
      fileExportOverseasShipmentTracking,
      fileExportOutboardReport,
      getLocation,
    };
  },
});
